<template>
  <div
    class="fixed basket-container basket-container--simple max-w-428 w-full bg-white z-header flex flex-1 flex-col border border-grey500 bottom-24 md:bottom-48 right-16 md:right-48 shadow-md"
    :class="{
      '!max-w-[460px]': isDKMarket
    }"
    @mouseover="clearPopupTimeout"
    @mouseleave="setPopupTimeout"
  >
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';

const config = useGlobalContentStore().config;

const isDKMarket = computed(() => {
  return config.currentMarket?.countryCode === 'DK';
});

const emit = defineEmits<{
  (event: 'clear-timeout'): void,
  (event: 'set-timeout'): void,
}>();

const clearPopupTimeout = () => {
  emit('clear-timeout');
};

const setPopupTimeout = () => {
 emit('set-timeout');
};
</script>
<style scoped>
.basket-container--simple {
  z-index: 999999;
  width: calc(100% - 32px);

  @screen md {
    width: 100%;
  }
}
</style>
